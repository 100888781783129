<template>
  <div>
    <div v-if="adminEditViewOption && !articleCurationChangeWindowExpired">
      <b-button :pressed.sync="editModeType" size="sm" class="my-1" variant="outline-dark" :article-index="articleIndex"
        >Edit {{ editLabel }}</b-button
      >
    </div>
    <div
      v-if="!adminEditViewOn()"
      class="entitiesVersions"
      :class="{ changeWindowExpired: articleCurationChangeWindowExpired }"
    >
      <entity
        :current-pipeline="articleFilters?.pipeline || pipelines.v2"
        :article="article"
        :article-index="articleIndex"
      />
    </div>
    <div
      v-if="adminEditViewOn()"
      class="entitiesVersions"
      :class="{ changeWindowExpired: articleCurationChangeWindowExpired }"
    >
      <h5>Entity - Category Sentiment Matrix</h5>
      <div v-if="article.excluded" class="overall-excluded">
        <span v-b-tooltip.hover title="entire article is excluded"><i class="fas fa-times-circle"></i></span> overall
      </div>
      <entity-polarity
        :current-pipeline="articleFilters?.pipeline || pipelines.v2"
        :article="article"
        :article-index="articleIndex"
      />
      <div class="polarity-actions" :class="{ changeWindowExpired: articleCurationChangeWindowExpired }">
        <b-button variant="primary" :disabled="!submitEnabled" class="mr-4" @click="submitPolarity">submit</b-button>
        <b-button variant="secondary" :disabled="!isDirty" @click="resetPolarity">reset</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import entity from './entity'
import entityPolarity from './entityPolarity'
import xconfig from '../../js/xconfig'
import xcomputed from '../../js/xcomputed'
import xlibs from '../../js/xlibs'

const { pipelines } = xconfig

export default {
  name: 'ArticlesEntitiesSection',
  components: {
    entity,
    entityPolarity
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    },
    polarityView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      articlePolarity: {
        _id: this.article._id,
        changes: {}
      },
      editModeType: false
    }
  },
  computed: {
    ...mapGetters(['articles', 'articleFilters', 'polarityChanges']),
    ...xcomputed,
    pipelines() {
      return pipelines
    },
    isDirty: function () {
      const dirty = _.get(this, ['polarityChanges', this.article._id])
      return dirty
    },
    submitEnabled: function () {
      return this.isDirty
    },
    editLabel: function () {
      const label = this.editModeType ? 'Entities' : 'Score / Category'
      return label
    },
    adminEditViewOption() {
      return this.isAdmin && this.curatingFrom === 'ingested'
    }
  },
  methods: {
    ...xlibs,
    resetPolarity() {
      const polarityChangePath = `${this.article._id}`
      this.$store.dispatch('clearPolarityChange', { polarityChangePath })
    },
    adminEditViewOn() {
      return this.isAdmin && this.curatingFrom === 'ingested' && this.editModeType
    },
    async submitPolarity() {
      this.articlePolarity.changes = _.get(this, ['polarityChanges', this.article._id])
      await xlibs.submitArticlePolarity.call(this, this.articlePolarity, this.curatingFrom)
      _.set(this.articlePolarity, 'changes', {})
      this.resetPolarity()
      const updatedArticle = await this.fetchArticle(this.article._id)
      this.$store.dispatch('updateArticle', updatedArticle)
    }
  }
}
</script>
