import xconfig from './xconfig'
import xlibs from './xlibs'

const xcomputed = {
  isAdmin() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 21) return true
    return false
  },
  isEntityCurator() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 22) return true
    return false
  },
  isSentimentCurator() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 24) return true
    return false
  },
  isSpotlightAnalyst() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 25) return true
    return false
  },
  isLive() {
    return xcomputed.xmanMode() === 'live'
  },
  isStatic() {
    return xcomputed.xmanMode() === 'static'
  },
  xmanMode() {
    return window.xmanConfig.xmanMode
  },
  featureSwitches() {
    return _.get(window, 'xmanConfig.featureSwitches', {})
  },
  featureSwitchesOn() {
    const onSwitches = _.keys(_.pickBy(xcomputed.featureSwitches()))
    return onSwitches
  },
  curatingFrom() {
    const propValue = _.get(this, '$options.propsData.curatingFrom')
    const optionName = _.camelCase(this.$options.name)
    return propValue || optionName
  },
  formattedDate() {
    return (dateMs, localize = false) => {
      if (!dateMs) return ''
      return localize ? moment(dateMs).toString() : moment.utc(dateMs).format('ddd, MMM D YYYY, HH:mm:ss').toString()
    }
  },
  currentCount() {
    this.count.currentCount = _.get(self, 'articles.length', 0)
    return this.count.currentCount
  },
  showLoadMore() {
    const self = this
    return self.count.totalCount > self.count.currentCount
  },
  environment() {
    return window.xmanConfig.env
  },
  curationItemType() {
    const mode = _.includes(_.toLower(this.curatingFrom), 'spotlight') ? 'spotlights' : 'articles'
    return mode
  },
  subjectToChangeWindow() {
    if (_.get(xcomputed.featureSwitches(), 'disableArticleRecurationLock')) return false
    if (!xcomputed.isLive()) return false
    if (this.curationItemType === 'spotlights') {
      return this.curatingFrom === 'spotlightsSuppressed'
    }
    if (!_.get(this, 'article.curation')) return false
    if (_.get(this, 'article.curation.excluded', false) && _.get(this, 'article.curation.count') === 1) return false
    return true
  },
  getExpiredTime() {
    return moment.utc().startOf(xconfig.recurationWindow.unit).valueOf()
  },
  articleCurationChangeWindowExpired() {
    if (!this.subjectToChangeWindow) return false
    let now = moment.utc().valueOf()
    let expiredTime = xcomputed.getExpiredTime()
    const history = _.get(this, `article.curation.history`, [])
    const firstApproved = _.find(history, ['excluded', false])
    return _.get(firstApproved, 'dateMs', now) < expiredTime
  },
  spotlightCurationChangeWindowExpired() {
    // For Curation Change Expiration
    if (!this.subjectToChangeWindow) return false
    let now = moment.utc().valueOf()
    let expiredTime = xcomputed.getExpiredTime()
    const first = _.get(this, `spotlight.curation.first`, [])
    return _.get(first, 'dateMs', now) < expiredTime
  },
  spotlightCurationLockCheck() {
    // For feature locking
    if (_.get(xcomputed.featureSwitches(), 'disableSpotlightRecurationLock')) return false
    if (!xcomputed.isLive()) return false
    return false
  },
  currentPipeline() {
    return _.get(this, 'articleFilters.pipeline', 'v3')
  }
}

export default xcomputed
