import _ from 'lodash'
import xconfig from './xconfig'

let defaultVm = null

const xlibs = {
  initDefaultVm(defaultLayoutVm) {
    defaultVm = defaultLayoutVm
  },
  setCuratingFrom(value) {
    this.$store.dispatch('setCuratingFrom', value)
  },
  getUser() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    return user
  },
  logout() {
    localStorage.removeItem('user')
    Vue.$cookies.remove('xAuthToken')
    window.location = '/login'
  },
  setApproveDisabled(articles) {
    const curatingFrom = _.get(defaultVm, '$store.getters.curatingFrom')
    const approveDisabledDefault = curatingFrom !== 'incoming'
    // set reactive article properties (to be watched/updated by ui view)
    _.each(articles, function (article) {
      article['approveDisabled'] = approveDisabledDefault
    })
    return articles
  },
  setCurationDisabled(items) {
    // set reactive article/spotlight properties (to be watched/updated by ui view)
    _.each(items, function (item) {
      item['curationDisabled'] = {}
    })
    return items
  },
  getLanguage(code = 'en') {
    const languageCode = _.toLower(code)
    const language = _.find(xconfig.languages, { code: languageCode })
    const languageName = _.get(language, 'name', '')
    return `${languageName} (${languageCode})`
  },
  openArticle(article) {
    const self = this
    // const article = _.find(self.articles, ['_id', articleId])
    self.$store.dispatch('setModalArticle', article)
  },
  getArticlesBaseUrl(self) {
    let url = '/api/live'
    if (_.get(self, 'collectionType')) {
      url = `/api/${self.collectionType}`
      if (_.get(self, 'collectionName')) {
        url = `${url}/${self.collectionName}`
      }
    }
    return url
  },
  getFormParams(self) {
    const curationRequestType = xlibs.getCurationRequestType(self.curatingFrom)
    const sortDirection = _.get(self, 'articleFilters.sort.date.direction')
    const sort = sortDirection === 'asc' ? 1 : -1

    const params = {
      curatingFrom: curationRequestType,
      page: _.get(self, 'articleFilters.page', 0),
      sort,
      pipeline: _.get(self, 'articleFilters.pipeline', 'v2')
    }
    if (self.user) {
      self.$store.dispatch('setArticleFilter', { user: self.user })
    }
    if (_.get(self, 'articleFilters.user', 'none') !== 'none') {
      params.userId = self.articleFilters.user
    }
    if (_.get(self, 'articleFilters.polarityScoreModified') !== 'any') {
      params.polarityScoreModified = self.articleFilters.polarityScoreModified
    }
    if (_.get(self, 'articleFilters.companyReportingType', 'all') !== 'all') {
      params.companyReportingType = self.articleFilters.companyReportingType
    }
    if (_.get(self, 'articleFilters.curation')) {
      params.curation = self.articleFilters.curation
    }
    if (_.get(self, 'articleFilters.limit', -1) > -1) {
      params.limit = self.articleFilters.limit
    }
    if (_.get(self, 'articleFilters.startDate')) {
      params.startMs = moment.utc(self.articleFilters.startDate, xconfig.dateFormat).startOf('day').valueOf()
    }
    if (_.get(self, 'articleFilters.endDate')) {
      params.endMs = moment.utc(self.articleFilters.endDate, xconfig.dateFormat).endOf('day').valueOf()
    }
    if (_.get(self, 'articleFilters.sort.date.dateRangeType')) {
      params.dateRangeType = self.articleFilters.sort.date.dateRangeType
    }
    params.lens = _.get(self, 'articleFilters.lens', 'all')
    const companyId = _.trim(_.get(self, 'articleFilters.isinFilter'))
    if (_.size(companyId) >= xconfig.formsConfig.companyId.minLength) {
      params.companyId = companyId
    }
    const documentHash = _.trim(_.get(self, 'articleFilters.documentHash'))
    if (_.size(documentHash) >= xconfig.formsConfig.documentHash.length) {
      params.documentHash = documentHash
    }
    const spotlightId = _.trim(_.get(self, 'articleFilters.spotlightId'))
    if (_.size(spotlightId) === xconfig.formsConfig.spotlightId.maxLength) {
      params.spotlightId = spotlightId
    }
    const headlineFilter = _.trim(_.get(self, 'articleFilters.headlineFilter'))
    if (_.size(headlineFilter) >= xconfig.formsConfig.headline.minLength) {
      params.headline = headlineFilter
    }
    if (_.get(self, 'articleFilters.language', 'all') !== 'all') {
      params.language = self.articleFilters.language
    }
    if (_.get(self, 'articleFilters.sourceType', 'all') !== 'all') {
      params.sourceType = self.articleFilters.sourceType
    }
    if (_.get(self, 'articleFilters.reportEntityType')) {
      params.reportEntityType = self.articleFilters.reportEntityType
    }
    return params
  },
  async fetchArticle(articleId) {
    const self = this
    const baseUrl = xlibs.getArticlesBaseUrl(self)
    let urlType = `/article/${articleId}`
    const url = `${baseUrl}${urlType}`
    const params = xlibs.getFormParams(self)
    const $articleNode = $(`#article-${articleId}`)
    xlibs.showSearchSpinner(true)
    $articleNode.addClass('removing')
    const { data } = await axios
      .post(url, params)
      .catch((error) => {
        xlibs.requestFailure(error)
        return { data: {} }
      })
      .finally(() => {
        xlibs.showSearchSpinner(false)
        $articleNode.removeClass('removing')
      })
    return data
  },
  async applyFilters() {
    const self = this
    let cancelled = false
    if (_.get(self, '$root.requestInProgress')) {
      self.$root.requestInProgress.cancel('cancelled')
      self.$root.requestInProgress = false
    }
    const axiosSource = axios.CancelToken.source()
    const cancelToken = axiosSource.token
    _.set(self, '$root.requestInProgress', { cancel: axiosSource.cancel })
    const curationRequestType = xlibs.getCurationRequestType(self.curatingFrom)
    let dataType = _.includes(curationRequestType, 'spotlight') ? 'spotlights' : 'articles'
    const baseUrl = xlibs.getArticlesBaseUrl(self)
    let urlType = dataType === 'spotlights' ? `/spotlights/${curationRequestType}` : '/articles/fetch'
    if (_.includes(curationRequestType, 'reports')) {
      dataType = self.articleFilters.reportEntityType === 'spotlights' ? 'spotlights' : 'articles'
      urlType = `/${dataType}/fetchReports`
      self.showReportItemList = _.get(self, 'articleFilters.limit') === 0 ? false : true
    }
    const url = `${baseUrl}${urlType}`
    const params = xlibs.getFormParams(self)
    xlibs.showSearchSpinner()
    const { data } = await axios
      .post(url, params, { cancelToken })
      .catch((error) => {
        if (error.message === 'cancelled') {
          cancelled = true
        } else {
          xlibs.requestFailure(error)
        }
        return { data: {} }
      })
      .finally(() => {
        if (!cancelled) {
          xlibs.showSearchSpinner(false)
          self.$root.requestInProgress = false
        }
      })

    let resultSet = _.get(data, dataType)
    if (!resultSet) {
      return
    }
    if (self.articleFilters.page) {
      self.$store.dispatch(`add${_.upperFirst(dataType)}`, resultSet)
    } else {
      const updateCheckTags = _.get(self, 'articleFilters.limit') ? true : false
      self.$store.dispatch(`set${_.upperFirst(dataType)}`, resultSet, updateCheckTags)
    }
    self.$store.dispatch('updateCount', _.get(data, 'totalCount', 0), dataType)
    if (_.get(self, 'articleFilters.limit') !== 0 && _.get(self, 'articleFilters.sort.field.type')) {
      self.$store.dispatch('localSortArticles', _.get(self, 'articleFilters.sort.field'))
    }
    self.articleFilters.page ? xlibs.toNextItem() : xlibs.setTabFocus()
  },
  setEntityTagCheckboxes(articles) {
    // forceNullChecked: should entities with no excluded propery show as checked
    const forceNullChecked = _.get(defaultVm, '$store.getters.curatingFrom') === 'incoming'
    if (!forceNullChecked) return articles
    _.each(articles, function (article) {
      // v2
      _.each(_.get(article, 'dpv2.associationTags'), function (entity, entityIndex) {
        if (entity) {
          article.dpv2.associationTags[`${entityIndex}`].excluded =
            !!article.dpv2.associationTags[`${entityIndex}`].excluded
        }
      })
    })
    return articles
  },
  openExcludeArticle(articleId) {
    $('#articleExcludeModal #excludeArticleForm')[0].reset()
    const self = this
    const article = _.clone(_.find(self.articles, ['_id', articleId]))
    self.$store.dispatch('setExcludeModalArticle', article)
  },
  prepareApprovalModel(articleId) {
    this.$store.dispatch('cleanArticleStateTags', articleId)
    const article = _.find(this.articles, { _id: articleId })
    const articleTagged = _.pick(article, ['_id', 'dpv2.associationTags'])
    return articleTagged
  },
  requestFailure(error) {
    console.log('error:', error)
    let errorMessage = _.get(error, 'response.message') || _.get(error, 'response.data.error')
    if (!errorMessage) {
      errorMessage = 'unknown api error'
    }
    xlibs.showErrorAlert(errorMessage)
    if ([400, 401, 403].includes(_.get(error, 'response.status'))) {
      if (error.response.status === 400 && _.toLower(errorMessage) !== 'missing access token') {
        return
      }
      setTimeout(function () {
        xlibs.logout()
      }, 600)
    }
  },
  async submitCurationSocket(self, id, curationType, type = 'article') {
    const user = xlibs.getUser()
    const userId = user.id
    const curator = user.email
    const message = { userId, curationView: self.curatingFrom, curationType, curator }
    if (type === 'article') {
      message.articleId = id
    } else {
      message.spotlightId = id
    }
    await self.$socket.client.emit('xmanCurationSocket', message)
  },
  async approveArticle(articleId) {
    const self = this
    const approvedArticle = self.prepareApprovalModel(articleId)

    const curationRequestType = xlibs.getCurationRequestType(self.curatingFrom)
    const $articleNode = $(`#article-${articleId}`)

    $articleNode.addClass('removing')
    const baseUrl = xlibs.getArticlesBaseUrl(self)
    const url = `${baseUrl}/article/approve/${approvedArticle._id}`
    const payload = {
      article: approvedArticle,
      curatingFrom: curationRequestType
    }
    xlibs.showSearchSpinner()
    const { data } = await axios
      .post(url, payload)
      .catch((error) => {
        xlibs.requestFailure(error)
        $articleNode.removeClass('removing')
      })
      .finally(() => {
        xlibs.showSearchSpinner(false)
      })

    let alertMessage
    if (data.alreadyProcessed) {
      alertMessage = `Article Already Processed: ${approvedArticle._id}`
    } else if (data.ingested) {
      alertMessage = `<b>Approved</b> Article: ${approvedArticle._id}`
      xlibs.submitCurationSocket(self, approvedArticle._id, 'approved')
    } else if (data.excluded) {
      alertMessage = `<b>Excluded</b> Article: ${approvedArticle._id}`
      xlibs.submitCurationSocket(self, approvedArticle._id, 'excluded')
    }
    const approvedArticleIndex = _.findIndex(self.articles, ['_id', approvedArticle._id])
    if (alertMessage && approvedArticleIndex > -1) {
      xlibs.showMessageAlert(alertMessage)
      if (curationRequestType === 'ingested') {
        self.articles[approvedArticleIndex].approveDisabled = true
      } else {
        // self.$delete(self.articles, approvedArticleIndex)
        self.throttleApplyFilters = self.throttleApplyFilters
          ? self.throttleApplyFilters
          : _.throttle(xlibs.applyFilters, 500)
        self.throttleApplyFilters()
      }
      // todo: use events to update curationstats
      const curationStats = _.get(self, '$refs.curationStats') || _.get(self, '$parent.$refs.curationStats')
      if (_.get(curationStats, 'getThrottleCurationStats')) {
        curationStats.getThrottleCurationStats()
      }
    } else {
      xlibs.showErrorAlert(`unknown error ingesting article: ${approvedArticle._id}`)
    }
  },
  async submitSpotlightCuration(spotlightData) {
    const self = this
    const { spotlightId } = spotlightData
    spotlightData.pipeline = _.get(self, 'articleFilters.pipeline', 'v2')
    if (spotlightData.pipeline === 'v3' && !spotlightData.hourMs) {
      spotlightData.hourMs = spotlightData.startDateMs
    }
    const $spotlightNode = $(`#spotlight-${spotlightId}`)
    $spotlightNode.addClass('removing')
    const baseUrl = xlibs.getArticlesBaseUrl(self)
    const url = `${baseUrl}/spotlight/curate/${spotlightId}`

    // const curationRequestType = xlibs.getCurationRequestType(self.curatingFrom)
    xlibs.showSearchSpinner()
    const { data } = await axios
      .post(url, spotlightData)
      .catch((error) => {
        xlibs.requestFailure(error)
        $spotlightNode.removeClass('removing')
      })
      .finally(() => {
        xlibs.showSearchSpinner(false)
      })
    let alertMessage
    if (data.alreadyProcessed) {
      alertMessage = `Article Already Processed: ${spotlightId}`
    } else if (data.curationType) {
      alertMessage = `<b>${_.capitalize(data.curationType)}</b> Spotlight: ${spotlightId}`
      xlibs.submitCurationSocket(self, spotlightId, data.curationType, 'spotlight')
    }
    const spotlightIndex = _.findIndex(self.spotlights, ['spotlightId', spotlightId])
    if (alertMessage && spotlightIndex > -1) {
      xlibs.showMessageAlert(alertMessage)
      // TODO: apply pagination?
      if (self.articleFilters.page && self.spotlights.length > _.get(self, 'articleFilters.count.batchSize', 10)) {
        // if not on first page, only remove the spotlights to preserve article list
        if (!_.includes(_.toLower(self.curatingFrom), data.curationType)) {
          self.$store.dispatch('removeSpotlight', spotlightId)
        }
        // ToDo update modified spotlights in view
      } else {
        self.articleFilters.page = 0
        self.throttleApplyFilters = self.throttleApplyFilters
          ? self.throttleApplyFilters
          : _.throttle(xlibs.applyFilters, 500)
        self.throttleApplyFilters()
      }

      // todo: use events to update curationstats
      const curationStats = _.get(self, '$refs.curationStats') || _.get(self, '$parent.$refs.curationStats')
      if (_.get(curationStats, 'getThrottleCurationStats')) {
        curationStats.getThrottleCurationStats()
      }
    } else {
      xlibs.showErrorAlert(`unknown error setting spotlight curation: ${spotlightId}`)
    }
  },
  async submitArticlePolarity(articlePolarity, curatingFrom) {
    const self = this
    if (_.includes(curatingFrom, 'spotlight')) {
      await xlibs.submitSpotlightCuration.call(self, articlePolarity, curatingFrom)
      return
    }
    const articleId = articlePolarity._id
    const curationRequestType = xlibs.getCurationRequestType(curatingFrom)
    const $articleNode = $(`#article-${articleId}`)
    $articleNode.addClass('removing')
    const baseUrl = xlibs.getArticlesBaseUrl(self)
    const url = `${baseUrl}/article/polarity/${articleId}`
    const payload = {
      articlePolarity,
      curatingFrom
    }
    xlibs.showSearchSpinner()
    const { data } = await axios
      .post(url, payload)
      .catch((error) => {
        xlibs.requestFailure(error)
      })
      .finally(() => {
        xlibs.showSearchSpinner(false)
        $articleNode.removeClass('removing')
      })
    let alertMessage
    if (data.alreadyProcessed) {
      alertMessage = `Article Already Processed: ${articleId}`
    } else if (data.ingested) {
      alertMessage = `<b>Approved Sentiment</b> Article: ${articleId}`
      xlibs.submitCurationSocket(self, articleId, 'approved')
    } else if (data.excluded) {
      alertMessage = `<b>Excluded</b> Article: ${articleId}`
      xlibs.submitCurationSocket(self, articleId, 'excluded')
    }
    const articleIndex = _.findIndex(self.articles, ['_id', articleId])
    if (alertMessage && articleIndex > -1) {
      xlibs.showMessageAlert(alertMessage)
      if (curationRequestType === 'ingested') {
        self.articles[articleIndex].approveDisabled = true
      } else {
        // self.$delete(self.articles, approvedArticleIndex)
        self.throttleApplyFilters = self.throttleApplyFilters
          ? self.throttleApplyFilters
          : _.throttle(xlibs.applyFilters, 500)
        self.throttleApplyFilters()
      }
      // todo: use events to update curationstats
      const curationStats = _.get(self, '$refs.curationStats') || _.get(self, '$parent.$refs.curationStats')
      if (_.get(curationStats, 'getThrottleCurationStats')) {
        curationStats.getThrottleCurationStats()
      }
    } else {
      xlibs.showErrorAlert(`unknown error setting article polarity: ${articleId}`)
    }
  },
  getCurationRequestType(curatingFrom) {
    if (curatingFrom === 'incoming') {
      return 'uncurated'
    }
    return curatingFrom
  },
  downloadCSVFile(fileContent, name) {
    const blobType = 'text/plain;charset=utf-8'
    const fileContentBlob = new Blob([fileContent], { type: blobType })
    const fileName = this.getFileName(name)
    window.fileSaver.saveAs(fileContentBlob, fileName, true)
  },
  getFileName(name) {
    return `${moment().format('YYYY-MM-DD')}_${name}Report.csv`
  },
  getAliases(internalAliases, lowerCase) {
    if (!_.isString(internalAliases) && !_.isArray(internalAliases)) {
      return []
    }
    const aliasArray = []
    const initialAliasArray = _.isString(internalAliases) ? internalAliases.split(';') : internalAliases
    _.each(initialAliasArray, function (alias) {
      let trimmedAlias = _.trim(alias)
      if (lowerCase) {
        trimmedAlias = _.lowerCase(trimmedAlias)
      }
      if (trimmedAlias) {
        aliasArray.push(trimmedAlias)
      }
    })
    return aliasArray
  },
  getSummaryText({ article, displayArticle = {}, getOriginal = false, html = false }) {
    // text for v3 articles
    // Note: displayArticle.text will only exist for uncurated (pending) spotlight articles
    // article.full_text_en will exist for both curated and uncurated spotlight articles.
    // However, article.full_text_en may not exist for v3 lexisnexis spotlight articles,
    // so we need to default to "bullets_en" in these cases
    if (_.get(displayArticle, 'text')) {
      return _.get(displayArticle, 'text')
    }
    if (_.get(article, 'full_text_en')) {
      return _.get(article, 'full_text_en')
    }
    if (_.get(article, 'bullets_en')) {
      return _.get(article, 'bullets_en')
    }

    // text for v2 articles
    const enSummaryKey = html ? 'html' : 'originalText'
    if (_.get(article, 'humanLanguage', 'en') === 'en') {
      return _.get(article, enSummaryKey, _.get(article, 'summary', ''))
    }
    if (getOriginal) {
      return _.get(article, 'originalText')
    }
    return _.get(article, 'translatedFields.en.originalText')
  },
  getSummarisedBullets(article, getOriginal = false) {
    const originalBullets = _.get(article, 'dpv2.summarisedBullets')
    if (_.get(article, 'humanLanguage', 'en') === 'en') {
      return originalBullets
    }
    if (getOriginal) {
      return originalBullets
    }
    return _.get(article, 'dpv2.translatedFields.en.summarisedBullets')
  },
  getTitle(article, getOriginal = false) {
    if (_.get(article, 'humanLanguage', 'en') === 'en') {
      return article.title
    }
    if (getOriginal) {
      return _.get(article, 'title')
    }
    return _.get(article, 'translatedFields.en.title', article.title)
  },
  showSearchSpinner(show = true) {
    const $bulkApprove = $('.bulkApprove')
    const $searchSpinner = $('#search-spinner')
    const $requestOverlay = $('.request-overlay')
    if (show) {
      $bulkApprove.hide()
      $searchSpinner.show()
      $requestOverlay.show()
    } else {
      $bulkApprove.show()
      $searchSpinner.hide()
      $requestOverlay.hide()
    }
  },
  showPageSpinner(show = true) {
    const $bulkApprove = $('.bulkApprove')
    const $pageSpinner = $('#page-spinner')
    if (show) {
      $bulkApprove.hide()
      $pageSpinner.show()
    } else {
      $bulkApprove.show()
      $pageSpinner.hide()
    }
  },
  async getXmanUsers(defaultUser = null) {
    const url = '/api/allXmanUsers'
    const { data } = await axios.get(url).catch((error) => {
      xlibs.requestFailure(error)
    })
    const users = _.map(data, function (user) {
      return { _id: user._id, email: user.emailAddress }
    })
    const userSelections = defaultUser ? [defaultUser].concat(users) : users
    return userSelections
  },
  showErrorAlert(message) {
    const rand = Math.floor(Math.random() * 1000000)
    const alertId = `error_alert_${rand}`
    const $alertId = `#${alertId}`
    const $alertContainer = $('#alert-container')
    const alertHTML = `<div id="${alertId}" class="alert alert-danger alert-dismissible fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        ${message}
      </div>`
    $alertContainer.html(alertHTML)
    $('#articleModal').modal('hide')
    $('#articleExcludeModal').modal('hide')
    window.setTimeout(function () {
      $alertContainer.find($alertId).remove()
    }, xconfig.arlertTimeoutDuration)
  },
  showMessageAlert(message, type = 'success') {
    const rand = Math.floor(Math.random() * 1000000)
    const alertId = `alert_${rand}`
    const $alertId = `#${alertId}`
    const $alertContainer = $('#alert-container')
    const alertHTML = `<div id="${alertId}" class="alert alert-${type} alert-dismissible fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        ${message}
      </div>`
    $alertContainer.append(alertHTML)
    window.setTimeout(function () {
      $alertContainer.find($alertId).remove()
    }, xconfig.arlertTimeoutDuration)
  },
  copy(text) {
    const root = this.$root.$children[0]
    const el = root.$refs.copyTextContainer
    if (!el) {
      return
    }
    el.value = text
    el.className = ''
    _.defer(() => {
      el.select()
      document.execCommand('copy')
      el.className = 'd-none'
      xlibs.showMessageAlert(`Copied "${_.truncate(text, { length: 50 })}" to clipboard`, 'primary')
    })
  },
  moveByFocus() {
    const $active = $(document.activeElement)
    const isOnPage = $active.hasClass('onpage') || $active.parent().hasClass('onpage')
    if (document.activeElement.tabIndex > -1 && isOnPage) {
      // current item is tabbable and on page so move by tab
      return false
    }
    const tabItem = $('.article.onpage').find(':tabbable').filter('.onpage[tabIndex!=-1]').first()
    if (tabItem.length) {
      // current item is NOT tabbable and on page so move by tab so find a tabbable item onpage if possible
      tabItem.focus()
      // only do focus
      return true
    }
    // If there is no tabbable item on page, find the last onpage article and apply focus to first tabbable item
    $('.article.onpage').last().find(':tabbable').filter('[tabIndex!=-1]').first().focus()
    // move by tab (will force page move)
    return false
  },
  toNextItem() {
    this.moveByFocus() || $.tabNext()
  },
  toPrevItem() {
    this.moveByFocus() || $.tabPrev()
  },
  toNextArticle() {
    let $nextArticle
    if ($('.article').length) {
      $nextArticle = $('.article.onpage').next()[0] || $('.article')[0]
      $nextArticle.scrollIntoView()
      window.scrollBy(0, -30)
    }
    setTimeout(function () {
      if ($('.article.onpage').length) {
        $('.article.onpage').find(':tabbable')[0].focus()
      }
    }, 25)
  },
  setTabFocus() {
    this.moveByFocus()
  },
  keyNavigation(e) {
    const { keyboardMap } = xconfig
    let key = e.keyCode
    if (_.startsWith(e.target.className, 'form-')) {
      return
    }
    switch (key) {
      case keyboardMap.enter:
        e.preventDefault()
        this.toNextArticle()
        break
      case keyboardMap.spacebar:
        e.preventDefault()
        e.target.trigger('click')
        break
      case keyboardMap.escape:
        e.preventDefault()
        $('.close').trigger('click')
        break
      case keyboardMap.left:
        e.preventDefault()
        this.toPrevItem()
        break
      case keyboardMap.up:
        e.preventDefault()
        this.toPrevItem()
        break
      case keyboardMap.right:
        e.preventDefault()
        this.toNextItem()
        break
      case keyboardMap.down:
        e.preventDefault()
        this.toNextItem()
        break
      default:
    }
  },
  getAuthCookie() {
    return Vue.$cookies.get(xconfig.authCookieName)
  },
  authenitcateSocket(self) {
    const accesstoken = xlibs.getAuthCookie()
    self.$socket.client.emit('authenticate', { accesstoken })
  },
  initSocket(self) {
    xlibs.authenitcateSocket(self)
  },
  xmanCurationSocket(data) {
    const { userId, curationView, curationType, curator, articleId, spotlightId } = data
    let article, spotlight
    if (!_.isEmpty(articleId)) {
      article = _.find(this.articles, { _id: articleId })
    }
    if (!_.isEmpty(spotlightId)) {
      spotlight = _.find(this.spotlights, { spotlightId: spotlightId })
    }
    if (!_.isEmpty(article)) {
      article.curationDisabled = { userId, curationView, curationType, curator, articleId }
    }
    if (!_.isEmpty(spotlight)) {
      spotlight.curationDisabled = { userId, curationView, curationType, curator, spotlightId }
    }
  },
  getFlag(article) {
    const languageCode = _.toLower(_.get(article, 'humanLanguage'))
    const flag = _.find(_.get(xconfig, 'languages', []), (lang) => {
      return _.toLower(lang.code) === languageCode
    })
    const flagCode = _.get(flag, 'flagCode')
    return flagCode
  },
  async clearStores() {
    await this.$store.dispatch('setArticles', [])
    await this.$store.dispatch('setSpotlights', [])
    await this.$store.dispatch('clearSpotlightCurationActions')
    await this.$store.dispatch('updateCount', 0)
  },
  checkForPressReleaseTerms(articleText) {
    const articleTextLowerCase = _.toLower(articleText)
    const articleTextIncludesPressRelease = articleTextLowerCase.includes(xconfig.PRESS_RELEASE)

    if (articleTextIncludesPressRelease) {
      for (let term of xconfig.pressReleaseTerms) {
        if (articleTextLowerCase.includes(term) && term !== xconfig.PRESS_RELEASE) {
          return xconfig.pressReleaseTerms
        }
      }
    }
    return []
  },
  highlightSummary(article, displayArticle = {}, isSpotlight = false) {
    let aliasesV2 = []

    if (_.has(article, isSpotlight ? 'associationTags[0]' : 'dpv2.associationTags[0]')) {
      _.each(isSpotlight ? article.associationTags : article.dpv2.associationTags, function (tag) {
        if (tag.internalAliases) {
          const aliases = xlibs.getAliases(tag.internalAliases, true)
          if (_.isArray(aliases)) {
            aliasesV2 = aliasesV2.concat(aliases)
          }
        }
        if (tag.longName) {
          let longName = _.trim(tag.longName)
          longName = _.lowerCase(longName)
          aliasesV2.push(longName)
        }
      })
    }

    let allStringsToHighlight = _.uniq(aliasesV2).sort((a, b) => b.length - a.length)
    const articleText = xlibs.getSummaryText({ article, displayArticle, html: true })
    allStringsToHighlight = [...allStringsToHighlight, ...xlibs.checkForPressReleaseTerms(articleText)]

    let summary = `<span>${articleText}</span>`
    _.each(allStringsToHighlight, (phraseWord) => {
      if (phraseWord && phraseWord !== '') {
        summary = summary.replace(
          new RegExp(`(?!<)(${phraseWord})(?=[^<>]*?<)`, 'gi'),
          `<mark class="${
            xlibs.checkForPressReleaseTerms(articleText).includes(phraseWord) ? 'mark-pr' : 'mark-alias'
          }">$1</mark>`
        )
      }
    })

    return summary
  },
  triggerRefresh() {
    console.log(
      `triggerRefresh function called at ${moment().utc().format()} for interval of ${xconfig.recurationWindow.duration.humanize()}`
    )
    const refreshComponent = _.get(this, '$refs.baseComponentRef.$refs.refreshComponentRef')
    const curatingFrom = _.get(refreshComponent, 'curatingFrom')
    if (curatingFrom === 'ingested' || curatingFrom === 'excluded' || curatingFrom === 'spotlightsSuppressed') {
      xlibs.applyFilters.call(refreshComponent)
    }
    this.scheduleNextRefresh()
  },
  timeUntilExpiration() {
    const now = moment().utc()
    const nextExpireTime = moment().utc().startOf(xconfig.recurationWindow.unit).add(xconfig.recurationWindow.duration)
    return nextExpireTime.diff(now)
  },
  scheduleNextRefresh() {
    console.log('scheduleNextRefresh')
    const timeUntilNextExpiration = this.timeUntilExpiration()
    const footerComponent = _.get(this, '$refs.footerComponentRef')
    if (_.has(footerComponent, 'resetTimer')) footerComponent.resetTimer()
    let self = this
    this.autoloadTimeoutId = setTimeout(() => {
      self.triggerRefresh()
    }, timeUntilNextExpiration)
  }
}

export default xlibs
