var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"entitiesVersions"},[(_vm.appliedOnDate || _vm.isCurationStale)?_c('div',{staticClass:"spotlight-curation my-2"},[(_vm.appliedOnDate)?_c('div',{staticClass:"curation-applied"},[_c('i',{staticClass:"fas",class:_vm.isCurationStale ? 'fa-minus-circle text-warning' : 'fa-check text-success'}),_vm._v(" "),_c('b',[_vm._v("Applied")]),_vm._v(" to live spotlights on "+_vm._s(_vm.appliedOnDate)+" UTC\n      ")]):_vm._e(),_vm._v(" "),(_vm.isCurationStale)?_c('div',{staticClass:"curation-stale ml-3"},[(_vm.appliedOnDate)?_c('span',[_vm._v("* Note:")]):_vm._e(),_vm._v(" Recent curation changes have "),_c('b',[_vm._v("not")]),_vm._v(" yet been applied to live\n        spotlights.\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm._.get(_vm.spotlight, 'curation.curationType'))?_c('div',{staticClass:"spotlight-status float-left mx-2",class:{
        rejected: _vm._.get(_vm.spotlight, 'curation.rejected'),
        approved: !_vm._.get(_vm.spotlight, 'curation.rejected')
      }},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far",class:{
          'fa-eye-slash': _vm._.get(_vm.spotlight, 'curation.rejected'),
          'fa-eye': !_vm._.get(_vm.spotlight, 'curation.rejected')
        },attrs:{"title":`Spotlight ${_vm._.capitalize(_vm._.get(_vm.spotlight, 'curation.curationType'))}`}})]):_vm._e(),_vm._v(" "),_c('article-metadata',{attrs:{"article":_vm.article,"spotlight-lens":_vm.lens}}),_vm._v(" "),_c('h5',[_vm._v("Entity - Category Sentiment Matrix")]),_vm._v(" "),(_vm._.get(_vm.article, 'excluded'))?_c('div',{staticClass:"overall-excluded"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"entire article is excluded"}},[_c('i',{staticClass:"fas fa-times-circle"})]),_vm._v(" overall\n    ")]):_vm._e(),_vm._v(" "),_c('spotlight-polarity',{attrs:{"current-pipeline":_vm.articleFilters?.pipeline || _vm.pipelines.v2,"article":_vm.article,"article-index":_vm.articleIndex,"spotlight":_vm.spotlight,"spotlight-id":_vm.spotlight.spotlightId,"spotlight-lens":_vm.lens,"category":_vm.category,"org-tvl-id":_vm.orgTvlId,"curation-changes":_vm.curationChanges}}),_vm._v(" "),(!_vm.readOnly)?_c('div',{staticClass:"spotlight-suppression my-4",class:{
        curationExpired: _vm.spotlightCurationChangeWindowExpired
      }},[(_vm.curatingFrom !== 'spotlightsSuppressed')?_c('b-button',{staticClass:"mr-4 text-capitalize",attrs:{"variant":"danger"},on:{"click":_vm.selectSuppressYes}},[_vm._v("Suppress")]):_vm._e(),_vm._v(" "),(_vm.curatingFrom !== 'spotlightsApproved')?_c('span',[(_vm.curatingFrom === 'spotlightsSuppressed' && _vm.spotlightCurationChangeWindowExpired)?_c('span',{staticClass:"spotlightCurationChangeWindowExpired"},[_c('b-button',{staticClass:"mr-4 text-capitalize bg-deepskyblue"},[_vm._v("Approval Locked")]),_vm._v(" "),_c('i',{staticClass:"fa fa-icicles"})],1):_vm._e(),_vm._v(" "),(!_vm.spotlightCurationChangeWindowExpired)?_c('span',{staticStyle:{"position":"relative"}},[(_vm.curatingFrom === 'spotlightsSuppressed')?_c('i',{staticClass:"fa fa-clock expire-warning"}):_vm._e(),_vm._v(" "),_c('b-button',{staticClass:"mr-4 text-capitalize",attrs:{"variant":"success"},on:{"click":_vm.selectSuppressNo}},[_vm._v("Approve")])],1):_vm._e()]):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }