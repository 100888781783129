<template>
  <footer class="footer">
    <div class="container">
      <router-link class="logo-font" exact to="/xman" tabindex="-1"> Home </router-link>
      <span class="appVersion"> {{ xmanMode }}_{{ environment }}_{{ version }} </span>
      |
      <countdown v-if="!featureSwitches.disableArticleRecurationLock" :time="startTime" class="curationClock">
        <template slot-scope="props"
          ><span
            class="clock-body"
            :class="{
              'mild-warning': props[warningLevels.unit] < warningLevels.mild,
              'strong-warning': props[warningLevels.unit] < warningLevels.strong
            }"
          >
            <i class="fa fa-clock expire-warning" />
            Curation Change Window Locks in:
            <span class="timesSection"
              ><span v-if="props.minutes">{{ props.minutes }} minutes,</span> {{ props.seconds }} seconds</span
            ></span
          ></template
        >
      </countdown>
    </div>
  </footer>
</template>

<script>
import xconfig from '../js/xconfig'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import Vue from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)
export default {
  name: 'RwvFooter',
  data() {
    return {
      startTime: 0
    }
  },
  computed: {
    ...xcomputed,
    version() {
      return window.xmanConfig.version
    },
    warningLevels: () => {
      return xconfig.curationExpirationWarningLevels
    }
  },
  methods: {
    ...xlibs,
    resetTimer() {
      let newTime = xlibs.timeUntilExpiration()
      if (newTime === this.startTime) {
        // Fix case where newTime happens to be the same as the original startTime
        // newTime will not propogate unless it is different and countdown timer will not reset
        // force newTime down 1 millisecond as a work around
        newTime = newTime - 1
      }
      this.startTime = newTime
    }
  }
}
</script>

<style>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 25px;
  line-height: 25px;
  background-color: #f5f5f5;
  z-index: 1071;
}
.footer .appVersion {
  color: lightblue;
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.footer .logo-font {
  margin-right: 10px;
}
.footer .curationClock .clock-body {
  padding: 0 20px;
  margin: 0px 25px;
  /* border: solid 1px black;
  border-radius: 7px;
  background-color: lightblue; */
}
.footer .curationClock .clock-body .expire-warning {
  pointer-events: none;
}
.footer .curationClock .clock-body .timesSection {
  padding: 0 10px;
  border: solid 1px black;
  border-radius: 7px;
  background-color: lightblue;
  font-weight: 600;
}
.footer .curationClock .clock-body.mild-warning .timesSection {
  background-color: gold;
}

.footer .curationClock .clock-body.strong-warning .timesSection {
  background-color: red;
  color: white;
  font-size: larger;
}
</style>
